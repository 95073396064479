/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import { useUser } from 'hooks';
import { useCreateAccount } from 'hooks/CreateAccount';

import { Inputs } from './interfaces';

export function CreateAccount() {
  const { handleSubmit, register } = useForm<Inputs>();
  const { user } = useUser();
  const { createAccount, isLoading } = useCreateAccount();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = data => {
    if (data.company.length <= 0) {
      return message.error('Razão social é obrigatório');
    } else if (data.document.length <= 0) {
      return message.error('CNPJ é obrigatório');
    } else if (data.email.length <= 0) {
      return message.error('E-mail é obrigatório');
    } else if (data.name.length <= 0) {
      return message.error('Nome é obrigatório');
    } else if (data.password.length < 8) {
      return message.error('Senha deve conter no mínimo 8 caracteres');
    } else if (data.phone.length <= 0) {
      return message.error('Celular é obrigatório');
    } else if (data.registration.length <= 0) {
      return message.error('Inscrição estadual é obrigatório');
    } else {
      createAccount(data);
    }
  };

  useEffect(() => {
    if (user) {
      return navigate('/estabelecimentos');
    }
  }, [user]);

  return (
    <main>
      <section className="pt-14 pb-14 w-full h-screen bg-gray-50">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-7/12 px-4">
              <div className="flex justify-center mb-5">
                <img
                  className="h-16"
                  src="./images/horizontal.svg"
                  alt="Workflow"
                />
              </div>
              <div
                className="relative flex flex-col min-w-0 break-words mb-6 shadow-lg rounded-lg bg-white py-6"
                style={{ maxWidth: 900 }}
              >
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-gray-500 text-center mb-3 font-bold">
                    <h1 className="text-black text-2xl mb-5">
                      Criar sua conta
                    </h1>
                  </div>
                  <form className="mt-9" onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid grid-cols-6 gap-6">
                      <h2 className="col-span-6">Dados pessoais</h2>
                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nome
                        </label>
                        <input
                          type="text"
                          id="name"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('name')}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="document"
                          className="block text-sm font-medium text-gray-700"
                        >
                          CNPJ
                        </label>
                        <InputMask
                          mask="99.999.999/9999-99"
                          type="text"
                          id="document"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('document')}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Celular
                        </label>
                        <InputMask
                          mask="(99) 99999-9999"
                          type="text"
                          id="phone"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('phone')}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          E-mail
                        </label>
                        <input
                          type="email"
                          id="email"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('email')}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="password"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Senha
                        </label>
                        <input
                          type="password"
                          id="password"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('password')}
                        />
                      </div>

                      <hr
                        style={{ marginTop: 32, marginBottom: 28 }}
                        className="col-span-6"
                      />

                      <h2 className="col-span-6">Empresa</h2>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="postalCode"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Razão social
                        </label>
                        <input
                          type="text"
                          id="company"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('company')}
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="registration"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Inscrição estadual
                        </label>
                        <input
                          type="text"
                          id="registration"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                          {...register('registration')}
                        />
                      </div>
                    </div>

                    <div className="text-center mt-12">
                      <button
                        className="bg-orange-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        type="submit"
                        style={{ transition: 'all .15s ease' }}
                      >
                        {isLoading ? 'Carregando' : 'criar conta'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex mt-6 align-middle justify-center">
                <a onClick={() => navigate('/')} className="text-black">
                  <small>Voltar para o login</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
