import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import { Flyer } from 'components';
import { Layout } from 'containers';
import { useStore, useUser } from 'hooks';
import { FlyerModel } from 'models';
import api from 'services/api';

export function Flyers() {
  const navigate = useNavigate();
  const { flyers, getFlyers, removeFlyer } = useStore();

  const handleRemoveFlyer = useCallback(async (id: string) => {
    await removeFlyer(id);
    getFlyers();
  }, []);

  useEffect(() => {
    getFlyers();
  }, []);

  return (
    <Layout>
      <div>
        <section className="px-2">
          <div className="flex justify-between align-middle">
            <h1 className="text-2xl">Panfletos</h1>
            <button onClick={() => navigate('/criar-panfleto')}>
              Criar panfleto
            </button>
          </div>

          <div className="px-5">
            {flyers.length > 0 ? (
              <div className="max-w-3xl my-10 lg:max-w-7xl">
                <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                  {flyers.map(flyer => (
                    <Flyer
                      key={flyer.id}
                      data={flyer}
                      handleRemoveFlyer={(id: string) => handleRemoveFlyer(id)}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div>Nenhum panfleto cadastrado</div>
            )}
          </div>
        </section>
      </div>
    </Layout>
  );
}
