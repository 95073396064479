/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import ImageUploading, { ImageListType } from 'react-images-uploading';

import { message } from 'antd';
import { Layout } from 'containers';
import { format } from 'date-fns';
import { useStore } from 'hooks';

import * as S from './styles';

type Inputs = {
  expiration_date: string;
  images: File[];
  shop_id: string;
};

export function CreateFlyer() {
  const { stores, getStores, createFlyer } = useStore();
  const [files, setFiles] = useState<File[]>([]);
  const [thumbs, setThumbs] = useState<Array<string>>([]);
  const [loading, setLoading] = useState(false);
  const { handleSubmit, register } = useForm<Inputs>();

  const handleSelectImage = (imageList: ImageListType) => {
    const newThumbs: string[] = [];
    const newFiles: File[] = [];

    imageList.map((file: any) => {
      console.log(
        'object.hasOwnProperty(data_url)',
        file.hasOwnProperty('data_url'),
      );
      if (file.hasOwnProperty('data_url')) {
        const fileSize = file.file.size / 1024 / 1024; // Transforma em Mb
        if (fileSize > 1) {
          message.error(`A imagem ${file.file.name} tem mais de 1mb`);
        } else {
          newThumbs.push(file.data_url);
          newFiles.push(file.file);
        }
      }
    });

    if (newThumbs.length > 0) {
      setThumbs(prev => [...prev, ...newThumbs]);
    }
    if (newFiles.length > 0) {
      setFiles(prev => [...prev, ...newFiles]);
    }
  };

  const renderThumbs = thumbs.map((file: any, index) => (
    <S.ThumbImageContainer key={file}>
      <div style={S.thumb}>
        <div style={S.thumbInner}>
          <img src={file} style={S.img} alt="Preview" />
        </div>
      </div>
    </S.ThumbImageContainer>
  ));

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    const body = {
      expiration_date: data.expiration_date,
      images: files,
      shop_id: data.shop_id,
    };

    await createFlyer(body);

    setLoading(false);
  };

  useEffect(() => {
    getStores();
  }, []);

  return (
    <Layout>
      <>
        {stores.length > 0 && (
          <div>
            <section>
              <div>
                <div className="md:col-span-1">
                  <div className="px-4 sm:px-0">
                    <h1 className="text-2xl">Criar panfleto</h1>
                  </div>
                </div>
                <div className="mt-10 md:col-span-2">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="shadow sm:rounded-md sm:overflow-hidden">
                      <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="opening_time"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Selecione o estabelecimento
                          </label>

                          <select
                            {...register('shop_id')}
                            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                            defaultValue={stores[0].id}
                          >
                            {stores.map(store => (
                              <option key={store.id} value={store.id}>
                                {store.name} - {store.address} {store.number},{' '}
                                {store.city}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Panfletos
                          </label>
                          <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                            <section className="container">
                              <ImageUploading
                                multiple
                                value={files}
                                onChange={handleSelectImage}
                                maxNumber={15}
                                dataURLKey="data_url"
                              >
                                {({ onImageUpload, isDragging, dragProps }) => (
                                  <div className="space-y-1 text-center upload__image-wrapper">
                                    <button
                                      type="button"
                                      style={
                                        isDragging
                                          ? { color: 'red' }
                                          : undefined
                                      }
                                      onClick={onImageUpload}
                                      {...dragProps}
                                    >
                                      <svg
                                        className="mx-auto h-12 w-12 text-gray-400"
                                        stroke="currentColor"
                                        fill="none"
                                        viewBox="0 0 48 48"
                                        aria-hidden="true"
                                      >
                                        <path
                                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                          strokeWidth="2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        ></path>
                                      </svg>
                                      <span className="cursor-pointer">
                                        Clique aqui para selecionar
                                      </span>
                                      <p className="text-xs text-gray-500">
                                        Permitido apenas PNG e JPG
                                      </p>
                                    </button>
                                  </div>
                                )}
                              </ImageUploading>
                              <S.ThumbsContainer>
                                {renderThumbs}
                              </S.ThumbsContainer>
                              {thumbs.length > 0 && (
                                <S.ClearSelectionButton
                                  onClick={() => {
                                    setFiles([]);
                                    setThumbs([]);
                                  }}
                                >
                                  Limpar seleção
                                </S.ClearSelectionButton>
                              )}
                            </section>
                          </div>
                        </div>

                        <div className="grid grid-cols-6 gap-6">
                          <div className="col-span-6 sm:col-span-3">
                            <label
                              htmlFor="closing_time"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Data de expiração
                            </label>
                            <div className="relative">
                              <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                <svg
                                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </div>
                              <input
                                {...register('expiration_date')}
                                className="border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="Ex: 01/01/2000"
                                type="date"
                                min={format(
                                  new Date().getTime() + 86400000,
                                  'yyyy-MM-dd',
                                )}
                              />
                            </div>
                            <div className="text-center mt-1">
                              <p>
                                <b>
                                  Coloque na data de expiração o dia que o
                                  panfleto irá expirar
                                </b>
                                <br />
                                Ex: Se o ultimo dia do panfleto for dia{' '}
                                {format(
                                  new Date().getTime() + 86400000,
                                  'dd/MM/yyyy',
                                )}{' '}
                                <br />
                                adicione o dia seguinte como data de expiração
                                {/* A data de expiração deve ser a partir de:{' '}
                                <b>
                                  {format(
                                    new Date().getTime() + 86400000,
                                    'dd/MM/yyyy',
                                  )}
                                </b> */}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                        <button
                          type="submit"
                          disabled={loading}
                          className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                        >
                          {loading ? 'Carregando' : 'Criar panfleto'}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    </Layout>
  );
}
