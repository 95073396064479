import { BrowserRouter } from 'react-router-dom';

import 'common/styles/tailwind.css';
import GlobalStyles from 'common/styles/global';
import 'antd/dist/antd.css';
import { AppProvider } from 'hooks';
import { Routes } from 'routes';

function Bootstrap() {
  return (
    <AppProvider>
      <BrowserRouter>
        <Routes />

        <GlobalStyles />
      </BrowserRouter>
    </AppProvider>
  );
}

export default Bootstrap;
