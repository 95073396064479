/*
 * @vinniciusgomes
 * This file is for:
 * This file is responsible for validating whether the client will access private routes if logged in or if it will use public routes.
 */
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';

import {
  CreateAccount,
  CreateFlyer,
  CreateStore,
  Dashboard,
  EditProfile,
  EditStore,
  Flyers,
  ForgotPassword,
  Success,
  NewPassword,
  NewPasswordSuccess,
  SignIn,
  Stores,
} from 'pages';

import { PrivateRoute, PublicRoute } from './Routes';

interface Routes {
  path: string;
  isPrivate: boolean;
  element: JSX.Element;
}

const routes: Routes[] = [
  {
    path: '/',
    isPrivate: false,
    element: <SignIn />,
  },
  {
    path: '/criar-conta',
    isPrivate: false,
    element: <CreateAccount />,
  },
  {
    path: '/esqueci-minha-senha',
    isPrivate: false,
    element: <ForgotPassword />,
  },
  {
    path: '/esqueci-minha-senha/sucesso',
    isPrivate: false,
    element: <Success />,
  },
  {
    path: '/nova-senha',
    isPrivate: false,
    element: <NewPassword />,
  },
  {
    path: '/nova-senha/sucesso',
    isPrivate: false,
    element: <NewPasswordSuccess />,
  },
  {
    path: '/dashboard',
    isPrivate: false,
    element: <Dashboard />,
  },
  {
    path: '/estabelecimentos',
    isPrivate: false,
    element: <Stores />,
  },
  {
    path: '/criar-estabelecimento',
    isPrivate: false,
    element: <CreateStore />,
  },
  {
    path: '/editar-estabelecimento/:id',
    isPrivate: false,
    element: <EditStore />,
  },
  {
    path: '/panfletos',
    isPrivate: false,
    element: <Flyers />,
  },
  {
    path: '/criar-panfleto',
    isPrivate: false,
    element: <CreateFlyer />,
  },
  {
    path: '/perfil',
    isPrivate: false,
    element: <EditProfile />,
  },
];

export function Routes() {
  return (
    <ReactRouterDomRoutes>
      {routes.map(({ path, isPrivate, element }) =>
        isPrivate ? (
          <Route
            key={path}
            path={path}
            element={<PrivateRoute>{element}</PrivateRoute>}
          />
        ) : (
          <Route
            key={path}
            path={path}
            element={<PublicRoute>{element}</PublicRoute>}
          />
        ),
      )}
    </ReactRouterDomRoutes>
  );
}
