import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import { Store } from 'components';
import { Layout } from 'containers';
import { useUser } from 'hooks';
import { StoreModel } from 'models';
import api from 'services/api';

export function Stores() {
  const [stores, setStores] = useState<StoreModel[]>([]);
  const navigate = useNavigate();
  const { user, getShopManagerInfo } = useUser();

  const handleRemoveStore = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/shop/${id}`);
        message.success('Estabelecimento removido com sucesso');
        getShopManagerInfo();

        setStores(stores.filter(store => store.id !== id));
      } catch (err) {
        message.error('Ocorreu um erro ao tentar excluir o estabelecimento!');
      }
    },
    [stores],
  );

  const handleGetStores = useCallback(async () => {
    const allStores: StoreModel[] = [];

    user.shops.forEach(async store => {
      allStores.push({
        ...store,
      });
    });

    setStores(allStores);
  }, [user]);

  useEffect(() => {
    getShopManagerInfo();
    handleGetStores();
  }, []);

  return (
    <Layout>
      <div>
        <section className="px-2">
          <div className="flex justify-between align-middle">
            <h1 className="text-2xl">Estabelecimentos</h1>
            <button onClick={() => navigate('/criar-estabelecimento')}>
              Criar estabelecimento
            </button>
          </div>

          <div className="px-5">
            <div className="max-w-3xl my-10 lg:max-w-7xl">
              <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                {stores.map(store => (
                  <Store
                    key={store.id}
                    data={store}
                    handleRemoveStore={(id: string) => handleRemoveStore(id)}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
