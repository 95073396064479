import React, { createContext, useCallback, useContext, useState } from 'react';

import { message } from 'antd';
import { Inputs } from 'pages/CreateAccount/interfaces';
import api from 'services/api';

import { CreateAccountContextData, FormData } from './interfaces';

const CreateAccountContext = createContext<CreateAccountContextData>(
  {} as CreateAccountContextData,
);

const CreateAccountProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleGetPlans = useCallback(async () => {
    try {
      const { data } = await api.get('/plan');

      return data;
    } catch (err) {
      message.error('Erro ao buscar planos');
    }
  }, []);

  const handleCreateAccount = useCallback(async (user: Inputs) => {
    setIsLoading(true);
    const body: FormData = {
      name: user.name,
      email: user.email,
      password: user.password,
      document: user.document,
      company: user.company,
      phone: user.phone,
      registration: user.registration,
    };

    try {
      const { data } = await api.post('shop-manager', body);

      if (data.message === 'Your registration is under review') {
        message.success('Sua conta está em revisão!');

        setTimeout(() => {
          window.location.href = '/';
        }, 2000);
      } else {
        message.error('Erro ao criar conta, tente novamente mais tarde!');
      }
    } catch (err) {
      setIsLoading(false);
      return message.error('Erro ao criar conta, tente novamente mais tarde!');
    }
  }, []);

  return (
    <CreateAccountContext.Provider
      value={{
        isLoading,
        getPlans: handleGetPlans,
        createAccount: handleCreateAccount,
      }}
    >
      {children}
    </CreateAccountContext.Provider>
  );
};

function useCreateAccount(): CreateAccountContextData {
  const context = useContext(CreateAccountContext);

  return context;
}

export { CreateAccountProvider, useCreateAccount };
