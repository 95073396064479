import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
} from 'react';

import { message } from 'antd';
import { FlyerModel, StoreModel } from 'models';
import api from 'services/api';

interface CreateStoreFormData {
  name: string;
  description: string;
  image: File;
  state: string;
  city: string;
  address: string;
  number: string;
  opening_time: string;
  closing_time: string;
  phone: string;
  payment_methods: string;
  maps: string;
  link: string;
}

interface CreateFlyerFormData {
  expiration_date: string;
  images: File[];
  shop_id: string;
}

interface StoreContextData {
  stores: StoreModel[];
  flyers: FlyerModel[];
  getFlyers: () => void;
  removeFlyer: (id: string) => void;
  getStores: () => void;
  createStore(store: CreateStoreFormData): void;
  editStore(
    store: CreateStoreFormData,
    storeId: string,
    callback: () => void,
  ): void;
  createFlyer(flyer: CreateFlyerFormData): void;
}

const StoreContext = createContext<StoreContextData>({} as StoreContextData);

const StoreProvider: React.FC = ({ children }) => {
  const [stores, setStores] = useState<StoreModel[]>([]);
  const [flyers, setFlyers] = useState<FlyerModel[]>([]);

  const handleGetStores = useCallback(async () => {
    const shops = localStorage.getItem('@folhetonamao:shops');

    if (shops) {
      setStores(JSON.parse(shops));
    }
  }, []);

  const handleGetFlyers = useCallback(() => {
    const shops = localStorage.getItem('@folhetonamao:shops');

    if (shops) {
      const flyersArray: any = [];

      JSON.parse(shops).forEach(async (store: StoreModel) => {
        store.flyers?.forEach(flyer => {
          flyersArray.push({
            ...flyer,
            shop: {
              id: store.id,
              name: store.name,
            },
          });
        });
      });

      setFlyers(flyersArray);
    }
  }, []);

  const handleRemoveFlyer = useCallback(
    async (id: string) => {
      try {
        await api.delete(`/flyer/${id}`);
        message.success('Flyer removido com sucesso');

        setFlyers(flyers.filter(flyer => flyer.id !== id));

        const { data } = await api.get('shop-manager');

        localStorage.setItem('@folhetonamao:shops', JSON.stringify(data.shops));
      } catch (err) {
        message.error('Ocorreu um erro ao tentar excluir o panfleto!');
      }
    },
    [flyers],
  );

  const handleCreateStore = useCallback(
    async (storeData: CreateStoreFormData) => {
      const data = new FormData();
      data.append('name', storeData.name);
      data.append('description', storeData.description);
      data.append('image', storeData.image);
      data.append('state', storeData.state);
      data.append('city', storeData.city);
      data.append('address', storeData.address);
      data.append('number', storeData.number);
      data.append('opening_time', storeData.opening_time);
      data.append('closing_time', storeData.closing_time);
      data.append('phone', storeData.phone);
      data.append('payment_methods', storeData.payment_methods);
      data.append('maps', storeData.maps);
      data.append('link', storeData.link);

      try {
        const { status } = await api.post('/shop', data);

        if (status === 201) {
          message.success('Estabelecimento criado com sucesso!');
          window.location.reload();
        }
      } catch (err) {
        alert(err);
      }
    },
    [],
  );

  const handleUpdateStore = useCallback(
    async (
      storeData: CreateStoreFormData,
      shopId: string,
      callback: () => void,
    ) => {
      const data = new FormData();
      data.append('name', storeData.name);
      data.append('description', storeData.description);
      data.append('state', storeData.state);
      data.append('city', storeData.city);
      data.append('address', storeData.address);
      data.append('number', storeData.number);
      data.append('opening_time', storeData.opening_time);
      data.append('closing_time', storeData.closing_time);
      data.append('phone', storeData.phone);
      data.append('payment_methods', storeData.payment_methods);
      data.append('maps', storeData.maps);
      data.append('link', storeData.link);

      if (storeData.image) {
        data.append('image', storeData.image);
      }

      try {
        const { status } = await api.post(`/shop/${shopId}`, data);

        if (status === 200) {
          message.success('Estabelecimento atualizado com sucesso');
          callback();
        }
      } catch (err) {
        alert(err);
      }
    },
    [],
  );

  const handleCreateFlyer = useCallback(
    async (flyerData: CreateFlyerFormData) => {
      const data = new FormData();

      if (flyerData.images.length === 0) {
        return message.error('Você precisa selecionar pelo menos uma imagem!');
      }

      if (flyerData.expiration_date === '') {
        return message.error('Você precisa preencher a data de expiração!');
      }

      data.append('shop_id', flyerData.shop_id);
      data.append('expiration_date', flyerData.expiration_date);

      for (let i = 0; i < flyerData.images.length; i++) {
        data.append('images[]', flyerData.images[i]);
      }

      try {
        const { status } = await api.post('/flyer', data);
        if (status === 201) {
          message.success('Panfleto criado com sucesso!');
          window.location.reload();
        }
      } catch (error: any) {
        message.error('Ocorreu um erro ao criar o panfleto!');
      }
    },
    [],
  );

  return (
    <StoreContext.Provider
      value={{
        stores,
        flyers,
        getFlyers: handleGetFlyers,
        removeFlyer: handleRemoveFlyer,
        getStores: handleGetStores,
        createStore: handleCreateStore,
        editStore: handleUpdateStore,
        createFlyer: handleCreateFlyer,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStore(): StoreContextData {
  const context = useContext(StoreContext);

  return context;
}

export { StoreProvider, useStore };
