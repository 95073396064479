import { primaryColors, secondaryColors } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.input`
  height: 5.2rem;
  max-width: 49.6rem;
  width: 100%;

  border: 0.1rem solid ${secondaryColors.grey100};
  border-radius: 0.4rem;
  padding: 1.6rem;
  outline: none;

  transition: border-color 0.2s ease-in-out;
  color: ${primaryColors.black};

  :focus {
    border-color: ${secondaryColors.orange100};
  }
`;
