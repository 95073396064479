import axios, { AxiosError } from 'axios';
import { env } from 'configs';

const api = axios.create({
  baseURL: env.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default api;
