import { useEffect } from 'react';

import { Store } from 'components';
import { Layout } from 'containers';
import { useStore, useUser } from 'hooks';

export function Dashboard() {
  const { getShopManagerInfo } = useUser();
  const { stores } = useStore();

  useEffect(() => {
    getShopManagerInfo();
  }, []);

  return (
    <Layout>
      <div>
        <section className="px-4">
          <div className="flex justify-between align-middle">
            <h1 className="text-2xl">Estabelecimentos</h1>
          </div>

          <div className="px-5">
            <div className="max-w-2xl my-10 lg:max-w-7xl">
              <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8 justify-between">
                {stores &&
                  stores.map(store => <Store key={store.id} data={store} />)}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
