import React, { createContext, useCallback, useState, useContext } from 'react';

import { message } from 'antd';
import axios from 'axios';
import { StoreModel } from 'models';
import api from 'services/api';

interface User {
  id: string;
  name: string;
  checked: number;
  email: string;
  shops: StoreModel[];
  created_at: Date;
  updated_at: Date;
  company: string;
  document: string;
  phone: string;
  registration: string;
}

interface UserState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface ResetPasswordCredentials {
  email: string;
}

interface UpdatePasswordCredentials {
  password: string;
  token: string;
}

interface RegisterCredentials {
  email: string;
  password: string;
  confirmPassword: string;
}

interface UserContextData {
  user: User;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  resetPassword(credentials: ResetPasswordCredentials): void;
  updatePassword(credentials: UpdatePasswordCredentials): void;
  createAccount(credentials: RegisterCredentials): void;
  getShopManagerInfo(): void;
}

const UserContext = createContext<UserContextData>({} as UserContextData);

const UserProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<UserState>(() => {
    const token = localStorage.getItem('@folhetonamao:token');
    const user = localStorage.getItem('@folhetonamao:user');

    if (token && user) {
      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as UserState;
  });

  const handleSignOut = useCallback(async () => {
    try {
      await api.get('shop-manager/logout');

      localStorage.removeItem('@folhetonamao:token');
      localStorage.removeItem('@folhetonamao:user');

      setData({} as UserState);
      window.location.href = '/';
    } catch (err) {
      localStorage.removeItem('@folhetonamao:token');
      localStorage.removeItem('@folhetonamao:user');

      setData({} as UserState);
    }
  }, []);

  const handleSignIn = useCallback(async ({ email, password }) => {
    try {
      const authResponse = await api.post('shop-manager/auth', {
        email,
        password,
      });

      const { token } = authResponse.data;

      localStorage.setItem('@folhetonamao:token', token);

      api.defaults.headers.common['authorization'] = `Bearer ${token}`;

      const userResponse = await api.get('shop-manager');

      const user = userResponse.data;

      localStorage.setItem('@folhetonamao:user', JSON.stringify(user));

      setData({ token, user });
    } catch (err: any) {
      if (err.response.data.message === 'Incorrect email or password') {
        return message.error('Usuário ou senha inválido!');
      }

      return message.error(
        'Não foi possível realizar o login, tente novamente mais tarde!',
      );
    }
  }, []);

  const handleCreateAccount = useCallback(async ({ name, email, password }) => {
    try {
      const { data } = await api.post('user', {
        name,
        state: localStorage.getItem('@folhetonamao:state'),
        city: localStorage.getItem('@folhetonamao:city'),
        email,
        password,
      });

      if (data.id) {
        message.success('Um e-mail de confirmação foi enviado para você!');
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.status === 422 && err.response?.data.email) {
          message.error('Esse e-mail já está sendo utilizado!');
        } else if (
          err.response?.status === 422 &&
          err.response?.data.password
        ) {
          message.error(err.response?.data.password[0]);
        } else {
          message.error('Ocorreu um erro ao tentar criar sua conta!');
        }
      } else {
        message.error('Ocorreu um erro ao tentar cadastrar o usuário!');
      }
    }
  }, []);

  const handleGetShopManagerInfo = useCallback(async () => {
    try {
      const { data } = await api.get('shop-manager');

      localStorage.setItem('@folhetonamao:user', JSON.stringify(data));
      localStorage.setItem('@folhetonamao:shops', JSON.stringify(data.shops));
    } catch (err: any) {
      if (err.response?.data.message === 'Token is invalid') {
        localStorage.clear();
        window.location.reload();
      }
    }
  }, []);

  const handleResetPassword = useCallback(async ({ email }) => {
    await api.post('/shop-manager/password/forgot', { email });
  }, []);

  const handleUpdatePassword = useCallback(async ({ password, token }) => {
    await api.put('/shop-manager/password/forgot', { password, token });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: data.user,
        signIn: handleSignIn,
        signOut: handleSignOut,
        resetPassword: handleResetPassword,
        updatePassword: handleUpdatePassword,
        getShopManagerInfo: handleGetShopManagerInfo,
        createAccount: handleCreateAccount,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

function useUser(): UserContextData {
  const context = useContext(UserContext);

  return context;
}

export { UserProvider, useUser };
