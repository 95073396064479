import { useRef, useState } from 'react';
import { FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import { env } from 'configs';
import { useOnClickOutside } from 'hooks';

import { StoreProps } from './interfaces';
import * as S from './styles';

export function Store({ data, handleRemoveStore }: StoreProps) {
  const [showOptions, setShowOptions] = useState(false);
  const ref = useRef(null);
  const navigate = useNavigate();

  useOnClickOutside(ref, () => setShowOptions(false));

  return (
    <S.Container ref={ref}>
      <S.Image
        src={`${env.apiUrl}/images/shops/${
          data.image
        }?updated=${new Date().getTime()}`}
        alt={data.name}
      />
      <S.StoreNameContainer>
        <S.StoreName>{data.name}</S.StoreName>

        {handleRemoveStore && (
          <div className="relative inline-block text-left">
            <div>
              <button
                onClick={() => setShowOptions(!showOptions)}
                type="button"
                id="menu-button"
                aria-expanded="true"
                aria-haspopup="true"
              >
                <FiSettings />
              </button>

              {showOptions && (
                <div
                  className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="menu-button"
                >
                  <div className="py-1" role="none">
                    <button
                      onClick={() =>
                        navigate(`/editar-estabelecimento/${data.id}`)
                      }
                      className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                    >
                      Editar
                    </button>
                    <button
                      onClick={() => handleRemoveStore(data.id)}
                      className="text-red-600 block w-full text-left px-4 py-2 text-sm"
                    >
                      Excluir
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </S.StoreNameContainer>
    </S.Container>
  );
}
