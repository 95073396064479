import { StoreProvider, UserProvider } from 'hooks';
import { CreateAccountProvider } from 'hooks/CreateAccount';

import { AppProviderProps } from './interfaces';

export function AppProvider({ children }: AppProviderProps) {
  return (
    <UserProvider>
      <CreateAccountProvider>
        <StoreProvider>{children}</StoreProvider>
      </CreateAccountProvider>
    </UserProvider>
  );
}

export default AppProvider;
