/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import { useUser } from 'hooks';

type Inputs = {
  password: string;
};

export function NewPassword() {
  const { handleSubmit, register } = useForm<Inputs>();
  const { user, updatePassword } = useUser();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = async data => {
    setLoading(true);
    try {
      if (data.password.length < 8) {
        setLoading(false);
        return message.error('Mínimo de 8 caracteres');
      }

      const body = {
        password: data.password,
        token,
      };

      await updatePassword(body);
      navigate('/nova-senha/sucesso');
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    new URL(location.href).searchParams.get('token');
    const params = new URL(location.href).searchParams;
    const resetToken = params.get('token');

    if (user || !resetToken) {
      return navigate('/estabelecimentos');
    }

    setToken(resetToken);
  }, [user]);

  return (
    <main>
      <section className="absolute w-full h-full bg-gray-50">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="flex justify-center mb-5">
                <img
                  className="h-16"
                  src="./images/horizontal.svg"
                  alt="Workflow"
                />
              </div>
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white py-6">
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-gray-500 text-center mb-3 font-bold">
                    <h1 className="text-black text-2xl mb-5">
                      Redefinir senha
                    </h1>
                  </div>
                  <form className="mt-9" onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Nova senha
                      </label>
                      <div className="border-0 rounded text-sm shadow focus:outline-none focus:ring w-full flex">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm focus:outline-none outline-none w-full"
                          placeholder="Nova senha"
                          min="8"
                          style={{ transition: 'all .15s ease' }}
                          {...register('password', { required: true })}
                        />
                        <button
                          type="button"
                          className="px-3 py-3 text-gray-700"
                          onClick={() => setShowPassword(prev => !prev)}
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible size={18} />
                          ) : (
                            <AiOutlineEye size={18} />
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-orange-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        type="submit"
                        disabled={loading}
                        style={{ transition: 'all .15s ease' }}
                      >
                        {loading ? 'Carregando...' : 'Atualizar'}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex mt-6 align-middle justify-center">
                <a onClick={() => navigate('/')} className="text-black">
                  <small>Voltar para o login</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
