/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'hooks';

type Inputs = {
  email: string;
  password: string;
};

export function SignIn() {
  const { handleSubmit, register } = useForm<Inputs>();
  const { signIn, user } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Inputs> = data => signIn(data);

  useEffect(() => {
    if (user) {
      navigate('/estabelecimentos');
    }
  }, [user]);

  return (
    <main>
      <section className="absolute w-full h-full bg-gray-50">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="flex justify-center mb-5">
                <img
                  className="h-16"
                  src="./images/horizontal.svg"
                  alt="Workflow"
                />
              </div>
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white py-6">
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <div className="text-gray-500 text-center mb-3 font-bold">
                    <h1 className="text-black text-2xl mb-5">
                      Acesse sua conta
                    </h1>
                  </div>
                  <form className="mt-9" onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Email
                      </label>
                      <input
                        type="email"
                        className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                        placeholder="Seu email"
                        style={{ transition: 'all .15s ease' }}
                        {...register('email', { required: true })}
                      />
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block text-gray-700 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Senha
                      </label>
                      <div className="border-0 rounded text-sm shadow focus:outline-none focus:ring w-full flex">
                        <input
                          type={showPassword ? 'text' : 'password'}
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm focus:outline-none outline-none w-full"
                          placeholder="Sua senha"
                          style={{ transition: 'all .15s ease' }}
                          {...register('password', { required: true })}
                        />
                        <button
                          type="button"
                          className="px-3 py-3 text-gray-700"
                          onClick={() => setShowPassword(prev => !prev)}
                        >
                          {showPassword ? (
                            <AiOutlineEyeInvisible size={18} />
                          ) : (
                            <AiOutlineEye size={18} />
                          )}
                        </button>
                      </div>
                    </div>

                    <div className="w-full flex align-center justify-end">
                      <a onClick={() => navigate('/esqueci-minha-senha')}>
                        Esqueci minha senha
                      </a>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        className="bg-orange-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        type="submit"
                        style={{ transition: 'all .15s ease' }}
                      >
                        Entrar
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="flex mt-6 align-middle justify-center">
                <a
                  onClick={() => navigate('/criar-conta')}
                  className="text-black"
                >
                  <small>Criar uma conta</small>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
