/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect } from 'react';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { useUser } from 'hooks';

export function Success() {
  const { user } = useUser();

  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/estabelecimentos');
    }
  }, [user]);

  return (
    <main>
      <section className="absolute w-full h-full bg-gray-50">
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <AiOutlineCheckCircle size={52} className="text-emerald-500" />
            <span className="ml-5 text-emerald-500 text-lg">
              Senha redefinida com sucesso,{' '}
              <a onClick={() => navigate('/')}>acesse sua conta</a>
            </span>
          </div>
        </div>
      </section>
    </main>
  );
}
