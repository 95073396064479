/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Fragment, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { useUser } from 'hooks';

interface LayoutProps {
  children: React.ReactElement;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

export function Layout({ children }: LayoutProps) {
  const navigate = useNavigate();
  const { signOut, user, getShopManagerInfo } = useUser();

  const navigation = [
    // {
    //   name: 'Início',
    //   href: '/dashboard',
    //   current: window.location.pathname === '/dashboard',
    // },
    {
      name: 'Estabelecimentos',
      href: '/estabelecimentos',
      current: window.location.pathname === '/estabelecimentos',
    },
    {
      name: 'Panfletos',
      href: '/panfletos',
      current: window.location.pathname === '/panfletos',
    },
    {
      name: 'Perfil',
      href: '/perfil',
      current: window.location.pathname === '/perfil',
    },
  ];

  const handleGetShopManagerInfo = useCallback(() => {
    try {
      getShopManagerInfo();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      navigate('/');
    }

    handleGetShopManagerInfo();
  }, [user]);

  return (
    <div className="min-h-full bg-white">
      {user && (
        <>
          <Disclosure as="nav" className="bg-gray-800 sticky top-0 z-50">
            {({ open }) => (
              <>
                <div className="mx-auto px-4 sm:px-6 lg:px-8">
                  <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <img
                          className="h-12"
                          src="/images/logo-white.svg"
                          alt="Workflow"
                        />
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                          {navigation.map(item => (
                            <span
                              key={item.name}
                              onClick={() => navigate(item.href)}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-900 text-white'
                                  : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                'px-3 py-2 rounded-md text-sm font-medium cursor-pointer',
                              )}
                              aria-current={item.current ? 'page' : undefined}
                            >
                              {item.name}
                            </span>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="hidden md:block">
                      <div className="ml-4 flex items-center md:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                          <div>
                            <Menu.Button className="max-w-xs bg-gray-800 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                              <span className="sr-only">Abrir menu</span>
                              <img
                                className="h-8 w-8 rounded-full"
                                src={`https://via.placeholder.com/50x50?text=${user.name.substring(
                                  0,
                                  1,
                                )}`}
                                alt=""
                              />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <Menu.Item>
                                {({ active }: { active: any }) => (
                                  <button
                                    onClick={() => signOut()}
                                    className={classNames(
                                      active ? 'bg-gray-100' : '',
                                      'block px-4 py-2 text-sm text-gray-700 w-max',
                                    )}
                                  >
                                    Sair
                                  </button>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Abrir menu</span>
                        {open ? (
                          <XIcon className="block h-6 w-6" aria-hidden="true" />
                        ) : (
                          <MenuIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="md:hidden">
                  <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    {navigation.map(item => (
                      <Disclosure.Button
                        key={item.name}
                        as="a"
                        href={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-900 text-white'
                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'block px-3 py-2 rounded-md text-base font-medium',
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="pt-4 pb-3 border-t border-gray-700">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <img
                          className="h-8 w-8 rounded-full"
                          src={`https://via.placeholder.com/50x50?text=${user.name.substring(
                            0,
                            1,
                          )}`}
                          alt=""
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">
                          {user.name}
                        </div>
                        <div className="text-sm font-medium leading-none text-gray-400">
                          {user.email}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                      <Disclosure.Button
                        onClickCapture={() => navigate('/perfil')}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                      >
                        Editar perfil
                      </Disclosure.Button>
                      <Disclosure.Button
                        onClickCapture={() => signOut()}
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                      >
                        Sair
                      </Disclosure.Button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>

          <main>
            <div className="max-w-screen-xl mx-auto py-6 sm:px-6 lg:px-8">
              {children}
            </div>
          </main>
        </>
      )}
    </div>
  );
}
