import {
  font,
  primaryColors,
  secondaryColors,
  breakpoints,
} from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 202px;
  width: 100%;

  a {
    text-decoration: none;
    font-size: 14px;
    font-weight: ${font.medium};
    color: ${secondaryColors.green100};
    margin-top: -5px;
    display: block;

    @media (max-width: ${breakpoints.small}) {
      font-size: 11px;
    }
  }
`;

export const Image = styled.img`
  width: 202px;
  height: 269px;

  border-radius: 4px;

  @media (max-width: ${breakpoints.small}) {
    width: 100%;
    height: auto;
  }
`;

export const StoreName = styled.span`
  display: block;
  font-weight: ${font.bold};
  font-size: 18px;
  color: ${primaryColors.black};

  @media (max-width: ${breakpoints.small}) {
    margin-top: 5px;
    font-size: 13px;
  }
`;

export const StoreNameContainer = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
