/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import ReactImageUploading, { ImageListType } from 'react-images-uploading';

import { Layout } from 'containers';
import { useStore } from 'hooks';

import * as S from './styles';

type Inputs = {
  name: string;
  description: string;
  image: File[];
  state: string;
  city: string;
  address: string;
  number: string;
  opening_time: string;
  closing_time: string;
  phone: string;
  payment_methods: string;
  link: string;
  maps: string;
};

export function CreateStore() {
  const [files, setFiles] = useState<File[]>([]);
  const [thumbs, setThumbs] = useState<Array<string>>([]);
  const { createStore } = useStore();
  const { handleSubmit, register } = useForm<Inputs>();

  const renderThumbs = thumbs.map((file: any, index) => (
    <S.ThumbImageContainer key={file}>
      <div style={S.thumb}>
        <div style={S.thumbInner}>
          <img src={file} style={S.img} alt="Preview" />
        </div>
      </div>
      <span
        onClick={() => {
          setThumbs([
            ...thumbs.slice(0, index),
            ...thumbs.slice(index + 1, thumbs.length),
          ]);
          setFiles([
            ...files.slice(0, index),
            ...files.slice(index + 1, files.length),
          ]);
        }}
      >
        Remover
      </span>
    </S.ThumbImageContainer>
  ));

  const handleSelectImage = (imageList: ImageListType) => {
    imageList.map((file: any) => {
      setThumbs(prev => [...prev, file.data_url]);
      setFiles(prev => [...prev, file.file]);
    });
  };

  const onSubmit: SubmitHandler<Inputs> = data => {
    const body = {
      name: data.name,
      description: data.description,
      image: files[0],
      state: data.state,
      city: data.city,
      address: data.address,
      number: data.number,
      opening_time: data.opening_time,
      closing_time: data.closing_time,
      phone: data.phone,
      payment_methods: data.payment_methods,
      link: data.link,
      maps: data.maps,
    };

    createStore(body);
  };

  return (
    <Layout>
      <div>
        <section>
          <div>
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h1 className="text-2xl">Criar estabelecimento</h1>
              </div>
            </div>
            <div className="mt-10 md:col-span-2">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="shadow sm:rounded-md sm:overflow-hidden">
                  <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                    <div className="col-span-6 sm:col-span-4">
                      <label
                        htmlFor="name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Nome do estabelecimento
                      </label>
                      <input
                        type="text"
                        {...register('name', { required: true })}
                        placeholder='Ex: "Mercado da esquina"'
                        className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="description"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Descrição
                      </label>
                      <div className="mt-1">
                        <textarea
                          {...register('description', { required: true })}
                          rows={3}
                          className="shadow-sm focus:ring-orange-500 focus:border-orange-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                          placeholder='Descrição sobre o estabelecimento"'
                        />
                      </div>
                      <p className="mt-2 text-sm text-gray-500">
                        Uma descrição sobre o estabelecimento
                      </p>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Logo
                      </label>
                      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                        <section className="container">
                          <ReactImageUploading
                            multiple
                            value={files}
                            onChange={handleSelectImage}
                            maxNumber={15}
                            dataURLKey="data_url"
                          >
                            {({ onImageUpload, isDragging, dragProps }) => (
                              <div className="space-y-1 text-center upload__image-wrapper">
                                <button
                                  type="button"
                                  style={
                                    isDragging ? { color: 'red' } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  <svg
                                    className="mx-auto h-12 w-12 text-gray-400"
                                    stroke="currentColor"
                                    fill="none"
                                    viewBox="0 0 48 48"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                      strokeWidth="2"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></path>
                                  </svg>
                                  <span className="cursor-pointer">
                                    Clique aqui para selecionar
                                  </span>
                                  <p className="text-xs text-gray-500">
                                    Permitido apenas PNG e JPG
                                  </p>
                                </button>
                              </div>
                            )}
                          </ReactImageUploading>
                          <S.ThumbsContainer>{renderThumbs}</S.ThumbsContainer>
                        </section>
                      </div>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="opening_time"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Horário de abertura
                        </label>
                        <input
                          type="time"
                          {...register('opening_time', { required: true })}
                          placeholder='Ex: "08:00"'
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="closing_time"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Horário de fechamento
                        </label>
                        <input
                          type="time"
                          {...register('closing_time', { required: true })}
                          placeholder='Ex: "20:30"'
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="payment_methods"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Métodos de pagamento
                        </label>
                        <input
                          type="text"
                          {...register('payment_methods', { required: true })}
                          placeholder="Obs: Separar por ponto e vírgula"
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="hidden sm:block" aria-hidden="true">
                      <div className="py-5">
                        <div className="border-t border-gray-200" />
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Estado
                        </label>
                        <select
                          {...register('state', { required: true })}
                          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 sm:text-sm"
                        >
                          <option value="AC">Acre</option>
                          <option value="AL">Alagoas</option>
                          <option value="AP">Amapá</option>
                          <option value="AM">Amazonas</option>
                          <option value="BA">Bahia</option>
                          <option value="CE">Ceará</option>
                          <option value="DF">Distrito Federal</option>
                          <option value="ES">Espírito Santo</option>
                          <option value="GO">Goiás</option>
                          <option value="MA">Maranhão</option>
                          <option value="MT">Mato Grosso</option>
                          <option value="MS">Mato Grosso do Sul</option>
                          <option value="MG">Minas Gerais</option>
                          <option value="PA">Pará</option>
                          <option value="PB">Paraíba</option>
                          <option value="PR">Paraná</option>
                          <option value="PE">Pernambuco</option>
                          <option value="PI">Piauí</option>
                          <option value="RJ">Rio de Janeiro</option>
                          <option value="RN">Rio Grande do Norte</option>
                          <option value="RS">Rio Grande do Sul</option>
                          <option value="RO">Rondônia</option>
                          <option value="RR">Roraima</option>
                          <option value="SC">Santa Catarina</option>
                          <option value="SP">São Paulo</option>
                          <option value="SE">Sergipe</option>
                          <option value="TO">Tocantins</option>
                        </select>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Cidade
                        </label>
                        <input
                          type="text"
                          {...register('city', { required: true })}
                          placeholder='Ex: "São Paulo"'
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="address"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Endereço
                        </label>
                        <input
                          type="text"
                          {...register('address', { required: true })}
                          placeholder='Ex: "Av. Principal nº 100"'
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="number"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Numero
                        </label>
                        <input
                          type="text"
                          {...register('number', { required: true })}
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="phone"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Telefone
                        </label>
                        <input
                          type="text"
                          {...register('phone', { required: true })}
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="link"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Link do site
                        </label>
                        <input
                          type="text"
                          {...register('link')}
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="maps"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Link do mapa
                        </label>
                        <input
                          type="text"
                          {...register('maps', { required: true })}
                          className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        />
                        <div className="text-center mt-1">
                          <p>
                            Para saber como pegar o link do mapa
                            <a
                              href="/files/como-obter-o-link-do-mapa-do-seu-estabelecimento.pdf"
                              target="_blank"
                              className="text-blue-600 hover:text-blue-700 transition duration-150 ease-in-out ml-1"
                              data-bs-toggle="tooltip"
                            >
                              clique aqui!
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                    <button
                      type="submit"
                      className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500"
                    >
                      Criar estabelecimento
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}
