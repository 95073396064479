/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import { Layout } from 'containers';
import { useUser } from 'hooks';
import api from 'services/api';

import { Inputs } from './interfaces';

export function EditProfile() {
  const [isLoading, setIsLoading] = useState(false);
  const { user, getShopManagerInfo } = useUser();
  const { handleSubmit, register } = useForm<Inputs>({
    defaultValues: {
      name: user.name,
      company: user.company,
      document: user.document,
      phone: user.phone,
      registration: user.registration,
    },
  });

  const onSubmit: SubmitHandler<Inputs> = data => handleUpdateUser(data);

  const handleUpdateUser = useCallback(async userData => {
    setIsLoading(true);
    try {
      const { data } = await api.put('shop-manager', userData);

      localStorage.setItem('@folhetonamao:user', JSON.stringify(data[0]));
      setIsLoading(false);
      message.success('Conta atualizada com sucesso!');
    } catch (err) {
      setIsLoading(false);
      message.error('Não foi possível atualizar a conta!');
    }
  }, []);

  useEffect(() => {
    getShopManagerInfo();
  }, []);

  return (
    <Layout>
      <div className="container mx-auto px-4 h-full">
        <div className="flex content-center items-center justify-center h-full">
          <div className="w-full px-4">
            <div className="relative flex flex-col min-w-0 break-words mb-6 bg-white py-6">
              <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div className="text-gray-500 text-center mb-3 font-bold">
                  <h1 className="text-black text-2xl mb-5">Editar conta</h1>
                </div>
                <form className="mt-9" onSubmit={handleSubmit(onSubmit)}>
                  <h2 className="col-span-6">Dados pessoais</h2>
                  <div className="col-span-12 mt-5 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      id="name"
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      {...register('name', { required: true })}
                    />
                  </div>

                  <div className="col-span-6 mt-5 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="document"
                      className="block text-sm font-medium text-gray-700"
                    >
                      CNPJ
                    </label>
                    <ReactInputMask
                      mask="99.999.999/9999-99"
                      type="text"
                      id="document"
                      defaultValue={user.document}
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      {...register('document', { required: true })}
                    />
                  </div>

                  <div className="col-span-6 mt-5 sm:col-span-3 lg:col-span-2">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Celular
                    </label>
                    <ReactInputMask
                      mask="(99) 99999-9999"
                      type="text"
                      id="phone"
                      defaultValue={user.phone}
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      {...register('phone', { required: true })}
                    />
                  </div>

                  <div className="col-span-12 mt-5 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-mail
                    </label>
                    <input
                      type="email"
                      disabled
                      id="email"
                      value={user.email}
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>

                  <hr
                    style={{ marginTop: 32, marginBottom: 28 }}
                    className="col-span-6"
                  />

                  <h2 className="col-span-6">Empresa</h2>

                  <div className="col-span-6 mt-5 sm:col-span-3">
                    <label
                      htmlFor="postalCode"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Razão social
                    </label>
                    <input
                      type="text"
                      id="company"
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      {...register('company', { required: true })}
                    />
                  </div>

                  <div className="col-span-6 mt-5 sm:col-span-3">
                    <label
                      htmlFor="registration"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Inscrição estadual
                    </label>
                    <input
                      type="text"
                      id="registration"
                      className="mt-1 focus:ring-orange-500 focus:border-orange-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                      {...register('registration', { required: true })}
                    />
                  </div>

                  <div className="text-center mt-12">
                    <button
                      className="bg-orange-500 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                      type="submit"
                      style={{ transition: 'all .15s ease' }}
                    >
                      {isLoading ? 'Carregando' : 'Salvar alterações'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
