import styled from 'styled-components';

export const Container = styled.div``;

export const ThumbsContainer = styled.aside`
  display: flex;
  margin-top: 1.6rem;
`;

export const ThumbImageContainer = styled.div`
  flex-direction: column;

  span {
    display: block;
    cursor: pointer;
  }
`;

export const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  marginRight: 8,
  height: 100,
};

export const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
};

export const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
