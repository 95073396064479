import { createGlobalStyle } from 'styled-components';

import { font, primaryColors, secondaryColors } from './theme';

export default createGlobalStyle`
   * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  body, input, textarea, button {
    font-family: ${font.family.Inter};
    font-weight: ${font.regular};
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: ${font.semiBold};
  }

  button {
    cursor: pointer;
  }

  [disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }

  ::-webkit-scrollbar-track {
    background-color: ${primaryColors.white};
  }

  ::-webkit-scrollbar {
    width: 2px;
    background: ${primaryColors.white};
  }

  ::-webkit-scrollbar-thumb {
    background: ${secondaryColors.blue100};
  }
`;
