import { secondaryColors } from 'common/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  border: 1px solid #eee;
  border-radius: 4px;

  overflow: hidden;
`;

export const HeaderLine = styled.div`
  width: 100%;
  height: 5px;
  background-color: ${secondaryColors.orange100};
`;

export const Content = styled.div`
  padding: 32px;
  text-align: center;
`;

export const PlanName = styled.span`
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 1px;
`;

export const PlanPrice = styled.h2`
  font-size: 32px;
  font-weight: 500;
  margin-top: 32px;
  margin-bottom: 52px;
`;

export const PlanDescription = styled.div`
  margin-bottom: 52px;
`;

export const PlanItensList = styled.ul`
  text-align: left;
  list-style: none;

  .disabled {
    opacity: 0.3;
  }

  li {
    display: flex;
    align-items: center;
    margin-top: 1px;

    svg {
      margin-right: 20px;
    }
  }
`;
