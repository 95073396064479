import { useRef, useState } from 'react';
import { FiSettings } from 'react-icons/fi';

import { env } from 'configs';
import { useOnClickOutside } from 'hooks';

import { FlyerProps } from './interfaces';
import * as S from './styles';

export function Flyer({ data, handleRemoveFlyer }: FlyerProps) {
  const [showOptions, setShowOptions] = useState(false);

  const ref = useRef(null);

  useOnClickOutside(ref, () => setShowOptions(false));

  return (
    <S.Container ref={ref}>
      <S.Image
        src={`${env.apiUrl}/images/flyers/${data.id}/${data.images[0]?.file}`}
        alt={data.shop.name}
      />

      <S.StoreNameContainer>
        <S.StoreName>{data.shop.name}</S.StoreName>
        <div className="relative inline-block text-left">
          <div>
            <button
              onClick={() => setShowOptions(!showOptions)}
              type="button"
              id="menu-button"
              aria-expanded="true"
              aria-haspopup="true"
            >
              <FiSettings />
            </button>

            {showOptions && (
              <div
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="menu-button"
              >
                <div className="py-1" role="none">
                  {/* <button
                    onClick={() => navigate(`/editar-panfleto/${data.id}`)}
                    className="text-gray-700 block w-full text-left px-4 py-2 text-sm"
                  >
                    Editar
                  </button> */}
                  <button
                    onClick={() => handleRemoveFlyer(data.id)}
                    className="text-red-600 block w-full text-left px-4 py-2 text-sm"
                  >
                    Excluir
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </S.StoreNameContainer>
    </S.Container>
  );
}
