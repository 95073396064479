import { font, primaryColors, secondaryColors } from 'common/styles/theme';
import styled, { css } from 'styled-components';

import { ButtonProps } from './interfaces';

const wrapperModifiers = {
  buttonType: {
    primary: () => css`
      background-color: ${secondaryColors.blue100};
      color: ${primaryColors.white};
    `,
    secondary: () => css`
      background-color: ${primaryColors.white};
      color: ${secondaryColors.blue100};
      border: 0.1rem solid ${secondaryColors.blue100};
      svg path {
        stroke: ${secondaryColors.blue100};
      }

      &:hover,
      :focus,
      :active {
        border: 0.1rem solid ${secondaryColors.blue100};
        background-color: ${secondaryColors.blue100};
        color: ${primaryColors.white};
        svg path {
          stroke: ${primaryColors.white};
        }
      }
    `,
    tertiary: () => css`
      background-color: transparent;
      color: ${secondaryColors.blue100};
      svg path {
        stroke: ${secondaryColors.blue100};
      }

      &:hover,
      :focus,
      :active {
        background-color: transparent;
      }

      &:hover {
        color: ${secondaryColors.blue100};
        svg path {
          stroke: ${secondaryColors.blue100};
        }
      }

      &:focus,
      :active {
        svg path {
          stroke: ${secondaryColors.blue100};
        }
      }
    `,
  },
  xsmall: () => css`
    height: 2px;
    padding: 4px 24px;
  `,
  small: () => css`
    height: 28px;
    padding: 4px 24px;
  `,
  medium: () => css`
    height: 32px;
    padding: 8px 24px;
  `,
  large: () => css`
    height: 36px;
    padding: 1.2px 24px;
  `,
  hasIcon: () => css`
    padding: 1.2px 16px;
    margin-left: 6px;
  `,
  loading: () => css`
    pointer-events: none;
    opacity: 0.5;
  `,
  error: () => css`
    pointer-events: none;
    opacity: 0.5;
  `,
  onlyIcon: {
    xsmall: () => css`
      padding: 4px;
      width: 32px;
      height: 32px;
    `,
    small: () => css`
      padding: 4px;
      width: 32px;
      height: 32px;
    `,
    medium: () => css`
      padding: 8px;
      width: 40px;
    `,
    large: () => css`
      padding: 1.2px;
      width: 48px;
    `,
  },
  iconDirection: {
    left: () => css`
      flex-direction: row;
      svg {
        margin-right: 6px;
      }
    `,
    right: () => css`
      flex-direction: row-reverse;
      svg {
        margin-left: 6px;
      }
    `,
  },
  disabled: {
    primary: () => css``,
    secondary: () => css``,
    tertiary: () => css`
      &:disabled {
        background-color: transparent;
        border: 0;
      }
    `,
  },
  fullWidth: () => css`
    width: 100%;
  `,
};

export const Wrapper = styled.button<ButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0;
  border-radius: 30px;
  box-sizing: border-box;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  font-size: 16px;
  font-weight: ${font.medium};

  text-align: center;

  svg {
    height: 2px;
    width: 2px;
  }

  span {
    font-size: 16px !important;
  }

  &:hover {
    background-color: ${secondaryColors.blue100};
  }
  &:focus,
  :active {
    background-color: ${secondaryColors.blue100};
  }

  &:disabled {
    background-color: #f6f6f6;
    border: 0.1rem solid #cccccc;
    cursor: not-allowed;

    span {
      color: #cccccc;
    }
    svg path {
      stroke: #cccccc;
    }
  }

  ${({
    buttonType,
    size,
    icon,
    iconDirection,
    onlyIcon,
    disabled,
    fullWidth,
    isLoading,
    error,
  }) => css`
    ${!!isLoading && wrapperModifiers.loading()};
    ${!!buttonType && !disabled && wrapperModifiers.buttonType[buttonType]()};
    ${!!disabled && !!buttonType && wrapperModifiers.disabled[buttonType]()};
    ${!!size && wrapperModifiers[size]()};
    ${!!icon && wrapperModifiers.hasIcon()};
    ${!!error && wrapperModifiers.error()};
    ${!onlyIcon &&
    !!iconDirection &&
    wrapperModifiers.iconDirection[iconDirection]()}
    ${!!onlyIcon && !!size && wrapperModifiers.onlyIcon[size]()}
    ${!!fullWidth && wrapperModifiers.fullWidth()}
  `};
`;
